import React, { PureComponent } from "react";

export default class TOS extends PureComponent {
  state = {};
  render() {
    return (
      <>
        <section className="bg-half bg-light d-table w-100 d-print-none">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 text-center">
                <div className="page-next-level">
                  <h4 className="title"> Terms Of Service</h4>
                  <ul className="list-unstyled mt-4">
                    <li className="list-inline-item h6 date text-muted">
                      {" "}
                      <span className="text-dark">Last Revised :</span> 23th
                      Sep, 2019
                    </li>
                  </ul>
                  <div className="page-next">
                    <nav aria-label="breadcrumb" className="d-inline-block">
                      <ul className="breadcrumb bg-white rounded shadow mb-0">
                        <li className="breadcrumb-item">
                          <a href="index.html">Hostarena</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Terms Of Service
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        <section className="section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div className="card shadow rounded border-0">
                  <div className="card-body">
                    <h2>
                      <strong>Terms and Conditions</strong>
                    </h2>
                    <p>Welcome to Hostarena!</p>
                    <p>
                      These terms and conditions outline the rules and
                      regulations for the use of Hostarena LTD's Website,
                      located at https://hostarena.org.
                    </p>
                    <p>
                      By accessing this website we assume you accept these terms
                      and conditions. Do not continue to use Hostarena if you do
                      not agree to take all of the terms and conditions stated
                      on this page.
                    </p>
                    <p>
                      The following terminology applies to these Terms and
                      Conditions, Privacy Statement and Disclaimer Notice and
                      all Agreements: "Client", "You" and "Your" refers to you,
                      the person log on this website and compliant to the
                      Company’s terms and conditions. "The Company",
                      "Ourselves", "We", "Our" and "Us", refers to our Company.
                      "Party", "Parties", or "Us", refers to both the Client and
                      ourselves. All terms refer to the offer, acceptance and
                      consideration of payment necessary to undertake the
                      process of our assistance to the Client in the most
                      appropriate manner for the express purpose of meeting the
                      Client’s needs in respect of provision of the Company’s
                      stated services, in accordance with and subject to,
                      prevailing law of Netherlands. Any use of the above
                      terminology or other words in the singular, plural,
                      capitalization and/or he/she or they, are taken as
                      interchangeable and therefore as referring to same.
                    </p>
                    <h3>
                      <strong>Cookies</strong>
                    </h3>
                    <p>
                      We employ the use of cookies. By accessing Hostarena, you
                      agreed to use cookies in agreement with the Hostarena
                      LTD's Privacy Policy.{" "}
                    </p>
                    <p>
                      Most interactive websites use cookies to let us retrieve
                      the user’s details for each visit. Cookies are used by our
                      website to enable the functionality of certain areas to
                      make it easier for people visiting our website. Some of
                      our affiliate/advertising partners may also use cookies.
                    </p>
                    <h3>
                      <strong>License</strong>
                    </h3>
                    <p>
                      Unless otherwise stated, Hostarena LTD and/or its
                      licensors own the intellectual property rights for all
                      material on Hostarena. All intellectual property rights
                      are reserved. You may access this from Hostarena for your
                      own personal use subjected to restrictions set in these
                      terms and conditions.
                    </p>
                    <p>You must not:</p>
                    <ul>
                      <li>Republish material from Hostarena</li>
                      <li>Sell, rent or sub-license material from Hostarena</li>
                      <li>
                        Reproduce, duplicate or copy material from Hostarena
                      </li>
                      <li>Redistribute content from Hostarena</li>
                    </ul>
                    <p>
                      Parts of this website offer an opportunity for users to
                      post and exchange opinions and information in certain
                      areas of the website. Hostarena LTD does not filter, edit,
                      publish or review Comments prior to their presence on the
                      website. Comments do not reflect the views and opinions of
                      Hostarena LTD,its agents and/or affiliates. Comments
                      reflect the views and opinions of the person who post
                      their views and opinions. To the extent permitted by
                      applicable laws, Hostarena LTD shall not be liable for the
                      Comments or for any liability, damages or expenses caused
                      and/or suffered as a result of any use of and/or posting
                      of and/or appearance of the Comments on this website.
                    </p>
                    <p>
                      Hostarena LTD reserves the right to monitor all Comments
                      and to remove any Comments which can be considered
                      inappropriate, offensive or causes breach of these Terms
                      and Conditions.
                    </p>
                    <p>You warrant and represent that:</p>
                    <ul>
                      <li>
                        You are entitled to post the Comments on our website and
                        have all necessary licenses and consents to do so;
                      </li>
                      <li>
                        The Comments do not invade any intellectual property
                        right, including without limitation copyright, patent or
                        trademark of any third party;
                      </li>
                      <li>
                        The Comments do not contain any defamatory, libelous,
                        offensive, indecent or otherwise unlawful material which
                        is an invasion of privacy
                      </li>
                      <li>
                        The Comments will not be used to solicit or promote
                        business or custom or present commercial activities or
                        unlawful activity.
                      </li>
                    </ul>
                    <p>
                      You hereby grant Hostarena LTD a non-exclusive license to
                      use, reproduce, edit and authorize others to use,
                      reproduce and edit any of your Comments in any and all
                      forms, formats or media.
                    </p>
                    <h3>
                      <strong>Hyperlinking to our Content</strong>
                    </h3>
                    <p>
                      The following organizations may link to our Website
                      without prior written approval:
                    </p>
                    <ul>
                      <li>Government agencies;</li>
                      <li>Search engines;</li>
                      <li>News organizations;</li>
                      <li>
                        Online directory distributors may link to our Website in
                        the same manner as they hyperlink to the Websites of
                        other listed businesses; and
                      </li>
                      <li>
                        System wide Accredited Businesses except soliciting
                        non-profit organizations, charity shopping malls, and
                        charity fundraising groups which may not hyperlink to
                        our Web site.
                      </li>
                    </ul>
                    <p>
                      These organizations may link to our home page, to
                      publications or to other Website information so long as
                      the link: (a) is not in any way deceptive; (b) does not
                      falsely imply sponsorship, endorsement or approval of the
                      linking party and its products and/or services; and (c)
                      fits within the context of the linking party’s site.
                    </p>
                    <p>
                      We may consider and approve other link requests from the
                      following types of organizations:
                    </p>
                    <ul>
                      <li>
                        commonly-known consumer and/or business information
                        sources;
                      </li>
                      <li>dot.com community sites;</li>
                      <li>
                        associations or other groups representing charities;
                      </li>
                      <li>online directory distributors;</li>
                      <li>internet portals;</li>
                      <li>accounting, law and consulting firms; and</li>
                      <li>educational institutions and trade associations.</li>
                    </ul>
                    <p>
                      We will approve link requests from these organizations if
                      we decide that: (a) the link would not make us look
                      unfavorably to ourselves or to our accredited businesses;
                      (b) the organization does not have any negative records
                      with us; (c) the benefit to us from the visibility of the
                      hyperlink compensates the absence of Hostarena LTD; and
                      (d) the link is in the context of general resource
                      information.
                    </p>
                    <p>
                      These organizations may link to our home page so long as
                      the link: (a) is not in any way deceptive; (b) does not
                      falsely imply sponsorship, endorsement or approval of the
                      linking party and its products or services; and (c) fits
                      within the context of the linking party’s site.
                    </p>
                    <p>
                      If you are one of the organizations listed in paragraph 2
                      above and are interested in linking to our website, you
                      must inform us by sending an e-mail to Hostarena LTD.
                      Please include your name, your organization name, contact
                      information as well as the URL of your site, a list of any
                      URLs from which you intend to link to our Website, and a
                      list of the URLs on our site to which you would like to
                      link. Wait 2-3 weeks for a response.
                    </p>
                    <p>
                      Approved organizations may hyperlink to our Website as
                      follows:
                    </p>
                    <ul>
                      <li>By use of our corporate name; or</li>
                      <li>
                        By use of the uniform resource locator being linked to;
                        or
                      </li>
                      <li>
                        By use of any other description of our Website being
                        linked to that makes sense within the context and format
                        of content on the linking party’s site.
                      </li>
                    </ul>
                    <p>
                      No use of Hostarena LTD's logo or other artwork will be
                      allowed for linking absent a trademark license agreement.
                    </p>
                    <h3>
                      <strong>iFrames</strong>
                    </h3>
                    <p>
                      Without prior approval and written permission, you may not
                      create frames around our Webpages that alter in any way
                      the visual presentation or appearance of our Website.
                    </p>
                    <h3>
                      <strong>Content Liability</strong>
                    </h3>
                    <p>
                      We shall not be hold responsible for any content that
                      appears on your Website. You agree to protect and defend
                      us against all claims that is rising on your Website. No
                      link(s) should appear on any Website that may be
                      interpreted as libelous, obscene or criminal, or which
                      infringes, otherwise violates, or advocates the
                      infringement or other violation of, any third party
                      rights.
                    </p>
                    <h3>
                      <strong>Prohibited Uses</strong>
                    </h3>
                    You may not use the Services to publish content or engage in
                    activity that is harmful to others or illegal under
                    applicable law. Any content that, in our judgment, violates
                    our Terms of Service, including this AUP, in any manner may
                    be removed from our servers (or otherwise disabled), with or
                    without notice. Examples of prohibited content and
                    activities can be found in this section.{" "}
                    <h3>
                      <strong>Zero Tolerance Spam Policy</strong>
                    </h3>
                    We maintain a zero tolerance policy for use of our network
                    or services to send unsolicited bulk or commercial e-mail,
                    or the sending, assisting, or commissioning the transmission
                    of commercial e-mail that does not comply with the U.S.
                    CAN-SPAM Act of 2003 (“SPAM”). Please review this section to
                    avoid action under our AUP.{" "}
                    <h3>
                      <strong>Defamation and Objectionable Content</strong>
                    </h3>
                    We respect that the Internet provides a forum for free and
                    open discussion and dissemination of information.
                    Accordingly, we generally do not screen, monitor or control
                    customer content. However, if we are made aware of content
                    that violates the law or this AUP, we will consider the
                    matter and take appropriate action as determined in our sole
                    discretion balancing the merits of the complaint with the
                    right to freedom of expression.{" "}
                    <h3>
                      <strong>Enforcement</strong>
                    </h3>
                    We may terminate your Services with or without notice upon
                    any violation of this AUP. This section describes how we may
                    enforce this AUP.{" "}
                    <h3>
                      <strong>Reporting AUP Violations</strong>
                    </h3>
                    If you feel you have discovered a violation of our AUP,
                    please review this section to learn about our reporting
                    procedures.{" "}
                    <h3>
                      <strong>Prohibited Content:</strong>
                    </h3>
                    <li>
                      No Illegal or Harmful Use – You may not use the Services
                      to publish content or engage in activity that is harmful
                      to others or illegal under applicable law, including
                      without limitation in connection with any of the following
                      illegal, harmful or fraudulent activities:
                    </li>
                    <li>
                      Disclosing private sensitive personal information about
                      others;
                    </li>
                    <li>Distributing malware or other malicious code;</li>
                    <li>
                      Engaging in the unlawful distribution of controlled
                      substances and drug contraband;
                    </li>
                    <li>
                      Engaging in the unlawful distribution of prescription
                      medications, including, but not limited to, promotion,
                      marketing, or sale of prescription medications without a
                      valid prescription; Gambling;
                    </li>
                    <li>
                      Hosting child pornography or content that is potentially
                      harmful to minors (Any website found to be hosting child
                      pornography or linking to child pornography will be
                      suspended immediately without notice and reported to law
                      enforcement or the National Center for Missing and
                      Exploited Children (NMEC);
                    </li>
                    <li>
                      Hosting FOREX, E-Gold Exchange, Second Life/Linden
                      Exchange, Ponzi, MLM/Pyramid Scheme, High-Yield Interest
                      Programs (HYIP) or related sites;
                    </li>
                    <li>
                      Hosting or linking to a website intended to deceive the
                      public including, but not limited to sites listed at
                      aa419.org & escrow-fraud.com;
                    </li>
                    <li>
                      Hosting or linking to an anonymous proxy server;
                      Infringing upon the Intellectual Property Rights of
                      Others. This includes, but is not limited to, the
                      unauthorized copying or distribution of movies, music,
                      books, photographs, software/warez, or any other
                      copyrighted work. If you believe that your intellectual
                      property rights are being infringed upon, please notify us
                      by completing the Report Abuse form, or emailing us at{" "}
                      <strong>abuse@hostarena.org</strong>;
                    </li>
                    <li>Money laundering;</li>
                    <li>
                      Phishing or engaging in identity theft; and Selling
                      weapons or ammunition.
                    </li>
                    <li>
                      No Unauthorized System Access or Network Abuse – You may
                      not use the Services to gain access into any network or
                      system without permission. Prohibited activities include:
                    </li>
                    <li>
                      {" "}
                      Accessing another network without permission, to probe or
                      scan for vulnerabilities or breach security or
                      authentication measures;
                    </li>
                    <li>
                      Attacking other networks (i.e. Denial of Service (DoS)
                      attacks);
                    </li>
                    <li>Intercepting or monitoring data without permission;</li>
                    <li>Running a file sharing site;</li>
                    <li>
                      Running any software that interfaces with an IRC (Internet
                      Relay Chat) network;
                    </li>
                    <li>
                      Using any deep-link, page-scrape, robot, crawl, index,
                      spider, offline reader, click spam, macro programs,
                      internet agent, or other automatic device, program,
                      algorithm or methodology which does the same things, to
                      use, access, copy, index, acquire information, generate
                      impressions or clicks, input information, store
                      information, search, generate searches, or monitor any
                      portion of our website or servers for any unauthorized
                      purpose;
                    </li>
                    <li>
                      Don’t Hamper System Performance – Consuming excessive
                      amount of server resources is prohibited. This leads to
                      server performance issues and may cause a disruption to
                      our systems or other customers. Use of any scripts or
                      processes that may adversely impact our systems is
                      prohibited.
                    </li>
                    <li>
                      {" "}
                      No Adult Content – You may not use the Services to
                      distribute pornography or other adult-related content or
                      offer any escort services.
                    </li>
                    <li>
                      {" "}
                      No Storage of Backups – Backing up personal data to a
                      hosting account is prohibited. Our Services are designed
                      to host your web site, not serve as a data repository. We
                      reserve the right to remove backups from your hosting
                      account.
                    </li>
                    <h3>
                      <strong>Zero Tolerance Spam Policy</strong>
                    </h3>{" "}
                    You may not use the Services to send spam or bulk
                    unsolicited messages. We maintain a zero tolerance policy
                    for use of its network or services in any manner associated
                    with the transmission, distribution or delivery of any bulk
                    e-mail, including unsolicited bulk or unsolicited commercial
                    e-mail, or the sending, assisting, or commissioning the
                    transmission of commercial e-mail that does not comply with
                    the U.S. CAN-SPAM Act of 2003 (“SPAM”). “Safe lists,”
                    purchased lists, and selling of lists will be treated as
                    SPAM. We may terminate the account of any User who sends out
                    SPAM with or without notice. Websites advertised via SPAM
                    (i.e. Spamvertised) may not be hosted on our servers. This
                    provision includes, but is not limited to, SPAM sent via
                    fax, phone, postal mail, email, instant messaging, or
                    usenet/newsgroups. No organization or entity listed in the
                    ROKSO may use our Services. Any User account which results
                    in our IP space being blacklisted will be immediately
                    suspended and/or terminated. Please familiarize yourself
                    with the CAN-SPAM Act. Information about the CAN-SPAM Act
                    may be found by accessing the following link:
                    https://www.ftc.gov/tips-advice/business-center/guidance/can-spam-act-compliance-guide-business{" "}
                    <h3>
                      <strong>Defamation and Objectionable Content</strong>
                    </h3>{" "}
                    We value the freedom of expression and encourages Users to
                    be respectful with the content they post. As a webhost, we
                    are not a publisher of User content and generally not in a
                    position to investigate the veracity of individual
                    defamation claims or to determine whether certain material,
                    which we may find objectionable, should be censored.
                    Accordingly, we generally require a court order from a court
                    of competent jurisdiction, as determined by the Company in
                    its sole discretion, to take down alleged defamatory or
                    objectionable content. However, we reserve the right to
                    disable or remove any content to prevent harm to others or
                    to the Company, as determined in our sole discretion.
                    Enforcement Resellers. If there is a violation of this AUP
                    by a User of a Reseller, we will suspend the account in
                    question and notify the Reseller so that the Reseller can
                    address the matter with the User. The occurrence of
                    additional violations on a User account may result in the
                    immediate suspensions or termination of your Reseller
                    account. Direct customers. Your Services may be terminated
                    with or without notice upon any violation of this AUP. If
                    applicable, violations will be reported to the appropriate
                    law enforcement agency. A failure to respond to an email
                    from our compliance team within forty-eight (48) hours, or
                    as otherwise specified in the email, may result in the
                    suspension or termination of your Services. We reserve the
                    right to enforce, or not enforce, this AUP in our sole
                    discretion.{" "}
                    <h3>
                      <strong>Your Privacy</strong>
                    </h3>
                    <p>Please read Privacy Policy</p>
                    <h3>
                      <strong>Reservation of Rights</strong>
                    </h3>
                    <p>
                      We reserve the right to request that you remove all links
                      or any particular link to our Website. You approve to
                      immediately remove all links to our Website upon request.
                      We also reserve the right to amen these terms and
                      conditions and it’s linking policy at any time. By
                      continuously linking to our Website, you agree to be bound
                      to and follow these linking terms and conditions.
                    </p>
                    <h3>
                      <strong>Removal of links from our website</strong>
                    </h3>
                    <p>
                      If you find any link on our Website that is offensive for
                      any reason, you are free to contact and inform us any
                      moment. We will consider requests to remove links but we
                      are not obligated to or so or to respond to you directly.
                    </p>
                    <p>
                      We do not ensure that the information on this website is
                      correct, we do not warrant its completeness or accuracy;
                      nor do we promise to ensure that the website remains
                      available or that the material on the website is kept up
                      to date.
                    </p>
                    <h3>
                      <strong>Disclaimer</strong>
                    </h3>
                    <p>
                      To the maximum extent permitted by applicable law, we
                      exclude all representations, warranties and conditions
                      relating to our website and the use of this website.
                      Nothing in this disclaimer will:
                    </p>
                    <ul>
                      <li>
                        limit or exclude our or your liability for death or
                        personal injury;
                      </li>
                      <li>
                        limit or exclude our or your liability for fraud or
                        fraudulent misrepresentation;
                      </li>
                      <li>
                        limit any of our or your liabilities in any way that is
                        not permitted under applicable law; or
                      </li>
                      <li>
                        exclude any of our or your liabilities that may not be
                        excluded under applicable law.
                      </li>
                    </ul>
                    <p>
                      The limitations and prohibitions of liability set in this
                      Section and elsewhere in this disclaimer: (a) are subject
                      to the preceding paragraph; and (b) govern all liabilities
                      arising under the disclaimer, including liabilities
                      arising in contract, in tort and for breach of statutory
                      duty.
                    </p>
                    <p>
                      As long as the website and the information and services on
                      the website are provided free of charge, we will not be
                      liable for any loss or damage of any nature.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
